var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar-title',{staticClass:"mt-4"},[_vm._v("Otros conceptos")]),_c('br'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"header-props":{ sortIcon: null },"items":_vm.otrosConceptos,"item-key":"ActasFiscalizacionConceptoId","search":_vm.searchTablaConceptos,"loading":_vm.loading,"footer-props":{
      'items-per-page-options': _vm.itemsPerPageOptions
    },"items-per-page":_vm.itemPerPage},scopedSlots:_vm._u([{key:"item.esperado",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.addDecimals(item.esperado)))])]}},{key:"item.recibido",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.addDecimals(item.recibido)))])]}},{key:"item.saldo",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.addDecimals(item.saldo)))])]}},{key:"item.interes",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.addDecimals(item.interes)))])]}},{key:"item.deuda",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.addDecimals(item.deuda)))])]}},{key:"item.importeInteres",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.addDecimals(item.importeInteres)))])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.searchTablaConceptos),callback:function ($$v) {_vm.searchTablaConceptos=$$v},expression:"searchTablaConceptos"}})],1),_c('v-col',{attrs:{"cols":"2","md":"2","align":"end"}},[_c('v-btn',{staticClass:"to-right",attrs:{"color":"primary"},on:{"click":function($event){return _vm.nuevoConcepto()}}},[_vm._v(" Nuevo ")])],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.nuevoConcepto(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.editIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Editar concepto")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.deleteConcepto(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.deleteIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Quitar concepto")])])]}},{key:"body.append",fn:function(ref){return [_c('tr',{staticClass:"totales text-center"},[_c('td'),_c('td',{staticClass:"text-start"},[_c('strong',[_vm._v("Totales por conceptos")])]),_c('td'),_c('td',{staticClass:"text-right"},[_c('strong',[_vm._v(_vm._s(_vm.addDecimals(_vm.totalImporte)))])]),_c('td',{staticClass:"text-right"},[_c('strong',[_vm._v(_vm._s(_vm.addDecimals(_vm.totalInteres)))])]),_c('td',{staticClass:"text-end"},[_c('strong',[_vm._v(" "+_vm._s(_vm.addDecimals(_vm.otrosConceptosImporte)))])]),_c('td')])]}}],null,true)}),(_vm.openNewConcepto)?_c('v-dialog',{attrs:{"max-width":"45%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeModalNewConcepto($event)}},model:{value:(_vm.openNewConcepto),callback:function ($$v) {_vm.openNewConcepto=$$v},expression:"openNewConcepto"}},[_c('NuevoConcepto',{attrs:{"otroConcepto":_vm.otroConcepto,"empresaSelected":_vm.empresaSelected,"actaId":_vm.actaId,"newActaFiscalizacionId":_vm.newActaFiscalizacionId,"actasFiscalizacionId":_vm.actasFiscalizacionId},on:{"closeModalNewConcepto":_vm.closeModalNewConcepto,"conceptosOtros":_vm.conceptosOtros}})],1):_vm._e(),_c('DeleteDialog',{attrs:{"titleProp":"¿Desea eliminar el concepto?","maxWidth":'30%',"openDelete":_vm.showDeleteModal},on:{"update:openDelete":function($event){_vm.showDeleteModal=$event},"update:open-delete":function($event){_vm.showDeleteModal=$event},"onDeleteItem":function($event){return _vm.confirmDelete()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }