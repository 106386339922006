<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ newConceptTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveConcepto()"
        >
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-autocomplete
                v-model="conceptoSelected"
                :items="conceptos"
                label="Concepto"
                :rules="rules.required"
                item-text="value"
                item-value="id"
                outlined
                return-object
                clearable
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-text-field
                dense
                outlined
                clearable
                v-model="periodo"
                label="Período"
                hint="Formato AAAAMM"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                persistent-hint
                type="text"
                :rules="periodo != null ? [validarPeriodo] : []"
                v-mask="'######'"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="py-0">
              <currency-input
                label="Importe"
                v-model="importe"
                oulined
                dense="false"
                outlined="false"
                :options="currencyOptions"
                :rules="rules.required"
              >
              </currency-input>
            </v-col>

            <v-col cols="6" class="py-0">
              <v-text-field
                v-model="cantidad"
                label="Cantidad"
                v-mask="'####'"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                :rules="
                  rules.required.concat(rules.minMaxNumber(cantidad, 1, 9999))
                "
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              cols="5"
              sm="5"
              md="5"
              class="d-flex py-0 justify-content-center"
            >
              <v-menu
                ref="menu5"
                v-model="menu5"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaInteresSelected"
                    label="Fecha cálculo interés"
                    :append-icon="calendarIcon"
                    v-mask="'##/##/####'"
                    :hint="
                      fechaInteresOnFocus
                        ? 'Formato DD/MM/AAAA'
                        : 'Se calcula el interés hasta la fecha indicada'
                    "
                    @blur="
                      (fechaInteres = parseDateToIso(fechaInteresSelected)),
                        (fechaInteresOnFocus = false)
                    "
                    outlined
                    clearable
                    autocomplete="off"
                    dense
                    v-bind="attrs"
                    v-on="on"
                    @focus="fechaInteresOnFocus = true"
                    persistent-hint
                    :rules="fechaInteresSelected != null ? rules.validDate : []"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaInteres"
                  no-title
                  @change="fechaInteresSelected = formatDate(fechaInteres)"
                  @input="menu5 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="1" class="p-0">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    @click="calcularInteres()"
                    :disabled="!isPeriodoComplete"
                    x-large
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ loadIcon }}
                  </v-icon>
                </template>
                <span>Calcular interés</span>
              </v-tooltip>
            </v-col>
            <v-col cols="6" class="pb-0">
              <currency-input
                label="Interés"
                v-model="interes"
                oulined
                dense="false"
                outlined="false"
                :options="currencyOptionsInteres"
                :rules="[]"
              >
              </currency-input>
            </v-col>
            <v-col cols="6" class="pb-0">
              <currency-input
                label="Importe + interés"
                v-model="total"
                disabled
                oulined
                dense="false"
                outlined="false"
                :options="currencyOptions"
                :rules="total > 0 ? [] : rules.required"
              >
                {{ total }}
              </currency-input>
            </v-col>
          </v-row>
          <v-row>
            <!-- Observaciones -->
            <v-col cols="12" class="pt-0">
              <v-textarea
                v-model="observaciones"
                auto-grow
                :rules="
                  observaciones !== null
                    ? [rules.maxLength(observaciones, 100)]
                    : []
                "
                dense
                outlined
                label="Observación"
                rows="1"
              ></v-textarea
            ></v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid || banderaGuardar"
          form="form"
          color="primary"
          :loading="banderaGuardar"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import getFormatPrice from "@/utils/helpers/getFormatPrice";

export default {
  name: "NuevoConcepto",
  components: { CurrencyInput },
  directives: { mask },
  props: {
    actasFiscalizacionId: { type: Number },
    newActaFiscalizacionId: {
      type: Number
    },
    actaId: {
      type: Number
    },
    empresaSelected: {
      type: Object
    },
    otroConcepto: {
      type: Object
    }
  },
  computed: {
    isPeriodoComplete() {
      return (
        this.periodo !== null &&
        this.periodo.length === 6 &&
        this.fechaInteresSelected !== null &&
        this.importe !== null &&
        this.validarPeriodo(this.periodo) != "Formato no válido"
      );
    }
  },
  watch: {
    importe: "totalGral",
    interes: "totalGral"
  },
  data: () => ({
    concepto: null,
    conceptos: [],
    loadIcon: enums.icons.BTN_LOAD,
    banderaGuardar: false,
    conceptoSelected: null,
    importe: null,
    menu5: false,
    fechaInteresSelected: null,
    fechaInteres: null,
    addInteres: null,
    rules: rules,
    periodo: null,
    interes: 0,
    total: null,
    cuit: null,
    calendarIcon: enums.icons.CALENDAR,
    fechaInteresOnFocus: false,
    currencyOptions: {
      locale: "nl-NL",
      currency: "USD",
      currencyDisplay: "narrowSymbol",
      valueRange: {
        min: -999999999999999,
        max: 999999999999999
      },
      precision: undefined,
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: true,
      hideNegligibleDecimalDigitsOnFocus: true,
      autoDecimalDigits: false,
      exportValueAsInteger: false,
      autoSign: true,
      useGrouping: true
    },
    currencyOptionsInteres: {
      locale: "nl-NL",
      currency: "USD",
      currencyDisplay: "narrowSymbol",
      valueRange: {
        min: 0,
        max: 999999999999999
      },
      precision: undefined,
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: true,
      hideNegligibleDecimalDigitsOnFocus: true,
      autoDecimalDigits: false,
      exportValueAsInteger: false,
      autoSign: true,
      useGrouping: true
    },
    cantidad: 1,
    observaciones: "",
    isFormValid: false,
    banderaGuardar: false,
    newConceptTitle: enums.titles.NUEVO_CONCEPTO
  }),
  created() {
    if (this.otroConcepto != null) {
      this.setOtroConcepto(this.otroConcepto);
      this.newConceptTitle = "Editar concepto";
    }
    this.setSelects();
  },
  methods: {
    ...mapActions({
      saveConceptoActasFiscalizacion: "aportes/saveConceptoActasFiscalizacion",
      getEmpresaByActaId: "aportes/getEmpresaByActaId",
      interesByCuit: "aportes/interesByCuit",
      getConceptos: "devengamientos/getConceptos",
      setAlert: "user/setAlert"
    }),

    async setSelects() {
      const conceptos = await this.getConceptos();
      this.conceptos = conceptos;
      if (this.empresaSelected != null) {
        this.cuit = this.empresaSelected.cuit;
      } else {
        const empresa = await this.getEmpresaByActaId(this.actaId);
        this.cuit = empresa.cuit;
      }
    },
    setOtroConcepto(item) {
      (this.actasFiscalizacionId = this.actasFiscalizacionId),
        (this.conceptoSelected = item.conceptoId),
        (this.periodo = item.periodo),
        (this.importe = item.importe),
        (this.cantidad = item.cantidad),
        (this.fechaInteresSelected = this.formatDate(
          item.fechaHastaCalculoInteres
        )),
        (this.interes = item.interes),
        (this.observaciones = item.observaciones);
    },
    async saveConcepto() {
      this.banderaGuardar = true;
      const data = {
        actasFiscalizacionConceptoId: this.otroConcepto
          ? this.otroConcepto.actasFiscalizacionConceptoId
          : 0,
        actasFiscalizacionId: this.actasFiscalizacionId,
        conceptoId: this.conceptoSelected.id
          ? this.conceptoSelected.id
          : this.conceptoSelected,
        importe: this.importe,
        interes: this.interes,
        fechaHastaCalculoInteres: this.parseDate(this.fechaInteresSelected),
        periodo: this.periodo,
        cantidad: this.cantidad,
        observaciones: this.observaciones
      };
      const res = await this.saveConceptoActasFiscalizacion(data);

      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.$emit("conceptosOtros");
        this.closeModal();
        this.banderaGuardar = false;
      }
    },
    validarPeriodo(v) {
      return (
        !v ||
        (/^([1-9][0-9][0-9][0-9])(0[1-9]|1[0-2])$/.test(v) && v !== "999912") ||
        "Formato no válido"
      );
    },
    addDecimals(x) {
      return getFormatPrice(x);
    },
    totalGral() {
      const importe = parseFloat(this.importe) || 0;
      const interes = parseFloat(this.interes) || 0;

      this.total = importe + interes;
    },
    async calcularInteres() {
      const data = {
        importe: this.importe,
        periodo: this.periodo,
        cuit: this.cuit,
        fechaHastaCalculoInteres: this.parseDate(this.fechaInteresSelected)
      };
      const res = await this.interesByCuit(data);
      this.interes = res;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${month}/${day}/${year}`;
    },
    formatDate(date) {
      if (!date) return null;
      const dateWithoutTime = date.split("T")[0]; // Obtener solo la parte de la fecha
      const [year, month, day] = dateWithoutTime.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    closeModal() {
      this.$emit("closeModalNewConcepto");
    }
  }
};
</script>

<style></style>
