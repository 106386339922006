<template>
  <div>
    <v-toolbar-title
      >Detalle del período <strong>{{ periodoProp }}</strong></v-toolbar-title
    >
    <br />
    <v-data-table
      id="tablaDetalle"
      :headers="headers"
      :header-props="{ sortIcon: null }"
      class="elevation-1"
      :search="searchTablaDetalle"
      :items="empleadosItems"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': itemsPerPageOptions
      }"
      :items-per-page="itemPerPage"
    >
      <template v-slot:[`item.esperado`]="{ item }">
        <span>{{ addDecimals(item.esperado) }}</span>
      </template>
      <template v-slot:[`item.recibido`]="{ item }">
        <span>{{ addDecimals(item.recibido) }}</span>
      </template>
      <template v-slot:[`item.saldo`]="{ item }">
        <span>{{ addDecimals(item.saldo) }}</span>
      </template>
      <template v-slot:[`item.interes`]="{ item }">
        <span>{{ addDecimals(item.interes) }}</span>
      </template>
      <template v-slot:[`item.deuda`]="{ item }">
        <span>{{ addDecimals(item.deuda) }}</span>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="searchTablaDetalle"
                :append-icon="searchIcon"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template>
            <v-text-field type="link" @click="quitarEmpleado(true, item)">
              Quitar
            </v-text-field>
          </template>
          <span>Quitar</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small @click="deleteItem(item)">
              {{ deleteIcon }}
            </v-icon>
          </template>
          <span>Quitar</span>
        </v-tooltip>
      </template>
      <template v-slot:[`body.append`]="{ headers }">
        <tr class="totales text-end">
          <td v-for="(header, i) in headers" :key="i">
            <div v-if="header.value == 'benNom'">
              <strong> Totales</strong>
            </div>
            <div v-if="header.value == 'esperado'">
              <strong> {{ addDecimals(totalEsperado) }}</strong>
            </div>

            <div v-if="header.value == 'recibido'">
              <strong> {{ addDecimals(totalRecibido) }}</strong>
            </div>
            <div v-if="header.value == 'saldo'">
              <strong>{{ addDecimals(totalSaldo) }}</strong>
            </div>
            <div v-if="header.value == 'interes'">
              <strong>{{ addDecimals(totalInteres) }}</strong>
            </div>
            <div v-if="header.value == 'deuda'">
              <strong>{{ addDecimals(totalDeuda) }}</strong>
            </div>
            <div v-else></div>
          </td>
        </tr>
      </template>
    </v-data-table>
    <DeleteDialog
      :textDelete="textDelete"
      :openDelete.sync="openDeleteDialog"
      @onDeleteItem="deleteEmpleadoConfirm()"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import enums from "@/utils/enums/index.js";
import DeleteDialog from "@/components/shared/DeleteDialog";
import getFormatPrice from "@/utils/helpers/getFormatPrice";

export default {
  props: ["actaId", "periodoProp", "cantPeriodos"],
  components: {
    DeleteDialog
  },

  data() {
    return {
      periodo: null,
      itemsPerPageOptions: [10, 20, 30, 40, 50],
      itemPerPage: 10,
      loading: false,
      searchIcon: enums.icons.SEARCH,
      editIcon: enums.icons.EDIT,
      deleteIcon: enums.icons.DELETE,
      empleadoToDelete: {},
      totalSaldo: 0,
      totalInteres: 0,
      totalDeuda: 0,
      totalRecibido: 0,
      textDelete: null,
      totalEsperado: 0,
      ultimoPeriodo: "",
      searchTablaDetalle: "",
      openDeleteDialog: false,
      editedIndex: -1,
      headers: [
        {
          text: "CUIL",
          align: "start",
          value: "cuil"
        },
        { text: "Afiliado", value: "benNom" },
        { text: "Convenio", value: "osNom" },
        { text: "Esperado", value: "esperado", align: "end" },
        { text: "Recibido", value: "recibido", align: "end" },
        { text: "Saldo", value: "saldo", align: "end" },
        { text: "Interés", value: "interes", align: "end" },
        { text: "Deuda", value: "deuda", align: "end" },
        { text: "Origen esperado", value: "origenCalculoEsperado", align: "end" },
        { text: "Acciones", value: "actions", align: "end" }
      ],
      empleadosItems: []
    };
  },
  created() {
    this.modalOpen = true;
    this.getDetalle();
  },

  watch:{
    periodoProp(val){
      if (val != this.periodo){
        this.getDetalle();
      }
    }
  },
  computed: {
    ...mapGetters({ user: "user/user" })
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      getDetallePeriodo: "aportes/getDetallePeriodo",
      deleteEmpleadoByActaIdBenId: "aportes/deleteEmpleadoByActaIdBenId"
    }),
    closeModal() {
      this.modalOpen = false;
      this.$emit("close", false);
      this.$refs.form.reset();
    },

    async getDetalle() {
      this.empleadosItems = [];
      this.periodo = this.periodoProp;
      const bodyParams = {
        actaId: this.actaId,
        periodo: this.periodo
      };
      await this.getDetallePeriodo({ bodyParams }).then(res => {
        this.empleadosItems = res;
        this.calcularTotales();
      });
    },
    async quitarEmpleado(item) {
      const detallePeriodoId = item.actaDetalleId;
      await this.deleteEmpleadoByActaIdBenId({ detallePeriodoId }).then(res => {
        if (res) {
          this.setAlert({ type: "success", message: "Eliminado con éxito." });
          this.openDeleteDialog = false;
          this.$bus.$emit("resetPeriodosDDJJ", this.empleadosItems.length);
          this.calcularTotales();
        } else {
          this.setAlert({
            type: "error",
            message: "Ocurrió un problema al eliminar. Intente nuevamente"
          });
        }
      });
    },

    deleteItem(item) {
      if (this.cantPeriodos > 1 || this.empleadosItems.length > 1) {
        this.textDelete = null;
      } else {
        this.textDelete = "Al eliminar este afiliado se eliminará el acta.";
      }
      this.empleadoToDelete = item;
      this.openDeleteDialog = true;
    },

    deleteEmpleadoConfirm() {
      var item = this.empleadoToDelete;
      this.editedIndex = this.empleadosItems.indexOf(item);
      this.empleadosItems.splice(this.editedIndex, 1);
      this.quitarEmpleado(item);
      this.empleadoToDelete = {};
      this.textDelete = null;
    },

    addDecimals(x) {
      return getFormatPrice(x);
    },
    calcularTotales() {
      this.totalSaldo = 0;
      this.totalInteres = 0;
      this.totalEsperado = 0;
      this.totalRecibido = 0;
      this.totalDeuda = 0;
      var empresas = this.empleadosItems;
      for (var i = 0; i < empresas.length; i++) {
        this.totalSaldo += empresas[i].saldo;
        this.totalInteres += empresas[i].interes;
        this.totalEsperado += empresas[i].esperado;
        this.totalRecibido += empresas[i].recibido;
        this.totalDeuda += empresas[i].deuda;
      }
    }
  }
};
</script>

<style lang="css" scoped>
.totales {
  font-weight: 500;
}
</style>
