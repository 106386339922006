<template>
  <div>
    <v-toolbar-title class="mt-4"
      >Períodos de <strong>{{ empresaNom }}</strong></v-toolbar-title
    >
    <br />
    <v-data-table
      id="tablaPeriodos"
      label="Periodos"
      :headers="headers"
      :header-props="{ sortIcon: null }"
      class="elevation-1"
      :items="periodos"
      :search="searchTablaPeriodos"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': itemsPerPageOptions
      }"
      :items-per-page="itemPerPage"
    >
      <template v-slot:[`item.esperado`]="{ item }">
        <span>{{ addDecimals(item.esperado) }}</span>
      </template>
      <template v-slot:[`item.recibido`]="{ item }">
        <span>{{ addDecimals(item.recibido) }}</span>
      </template>
      <template v-slot:[`item.saldo`]="{ item }">
        <span>{{ addDecimals(item.saldo) }}</span>
      </template>
      <template v-slot:[`item.interes`]="{ item }">
        <span>{{ addDecimals(item.interes) }}</span>
      </template>
      <template v-slot:[`item.deuda`]="{ item }">
        <span>{{ addDecimals(item.deuda) }}</span>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <!-- Busqueda -->
            <v-col cols="3">
              <v-text-field
                v-model="searchTablaPeriodos"
                :append-icon="searchIcon"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <!-- Recalcular -->
            <v-col
              cols="3"
              class="px-0"
              v-if="periodos.length > 0 && isEditingActa"
            >
              <v-btn
                color="primary"
                class="px-1 to-right"
                outlined
                @click="toggleModalRecalculoIntereses"
              >
                Recalcular intereses
              </v-btn>
            </v-col>
            <!-- Historial -->
            <v-col
              cols="3"
              class="px-0"
              v-if="periodos.length > 0 && isEditingActa"
            >
              <v-btn
                color="primary"
                class="px-1 to-right"
                outlined
                @click="toggleModalHistorialRecalculoIntereses"
              >
                Historial de recalculos
              </v-btn>
            </v-col>
            <!-- Detalle -->
            <v-col cols="3" v-if="periodos.length > 0">
              <v-tooltip top max-width="35%">
                <template v-slot:activator="{ attrs }">
                  <v-btn
                    color="primary"
                    :loading="loadingExportExcel"
                    align="end"
                    class="px-1 to-right"
                    v-bind="attrs"
                    outlined
                    @click="exportExcel"
                  >
                    Exportar detalle
                  </v-btn>
                </template>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              @click="getDetalle(true, item)"
            >
              {{ editIcon }}
            </v-icon>
          </template>
          <span>Editar período</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small @click="deletePeriodo(item)">
              {{ deleteIcon }}
            </v-icon>
          </template>
          <span>Quitar período</span>
        </v-tooltip>
      </template>
      <template v-slot:[`body.append`]="{ headers }">
        <tr class="totales text-end">
          <td v-for="(header, i) in headers" :key="i">
            <div v-if="header.value == 'periodo'">Totales por períodos</div>
            <div v-if="header.value == 'esperado'">
              <strong> {{ addDecimals(totalEsperado) }}</strong>
            </div>

            <div v-if="header.value == 'recibido'">
              <strong> {{ addDecimals(totalRecibido) }}</strong>
            </div>
            <div v-if="header.value == 'saldo'">
              <strong> {{ addDecimals(totalSaldo) }}</strong>
            </div>
            <div v-if="header.value == 'interes'">
              <strong>{{ addDecimals(totalInteres) }}</strong>
            </div>
            <div v-if="header.value == 'deuda'">
              <strong>{{ addDecimals(totalDeuda) }}</strong>
            </div>
            <div v-else></div>
          </td>
        </tr>
      </template>
    </v-data-table>

    <div v-if="openModalDetail">
      <br /><br />
      <DetallePeriodosDDJJ
        :actaId="actaId"
        :periodoProp="periodoId"
        :cantPeriodos="cantidadPeriodos"
        @getDetalle="getDetalle()"
      ></DetallePeriodosDDJJ>
    </div>
    <v-dialog
      v-model="recalcularInteresModal"
      v-if="recalcularInteresModal"
      @keydown.esc="toggleModalRecalculoIntereses"
      max-width="25%"
      persistent
    >
      <RecalcularInteresActaFiscalizacion
        :actasFiscalizacionId="actaId"
        @toggleModalRecalculoIntereses="toggleModalRecalculoIntereses()"
        @updatePeriodosDDJJ="getPeriodos(actaId)"

      ></RecalcularInteresActaFiscalizacion>
    </v-dialog>

    <v-dialog
      v-model="historialRecalculosModal"
      v-if="historialRecalculosModal"
      @keydown.esc="toggleModalHistorialRecalculoIntereses"
      max-width="60%"
      persistent
    >
      <LogRecalculosInteresActasFiscalizacion
        :actasFiscalizacionId="actaId"
        @toggleModalHistorialRecalculoIntereses="toggleModalHistorialRecalculoIntereses()"
        @updatePeriodosDDJJ="getPeriodos(actaId)"
      ></LogRecalculosInteresActasFiscalizacion>
    </v-dialog>

    <DeleteDialog
      :titleProp="titleDelete"
      :textDelete="textDelete"
      :maxWidth="'35%'"
      :openDelete.sync="openDeleteDialog"
      @onDeleteItem="deletePeriodoConfirm()"
    />
  </div>
</template>

<script>
import enums from "@/utils/enums/index.js";
import { mapActions, mapGetters } from "vuex";
import DetallePeriodosDDJJ from "@/components/modules/cuotas/aportes/DetallePeriodosDDJJ.vue";
import RecalcularInteresActaFiscalizacion from "@/components/modules/cuotas/aportes/RecalcularInteresActaFiscalizacion.vue";
import LogRecalculosInteresActasFiscalizacion from "@/components/modules/cuotas/aportes/LogRecalculosInteresActasFiscalizacion.vue";
import DeleteDialog from "@/components/shared/DeleteDialog";
import helpersExcel from "@/utils/helpers/importExportExcel";
import getFormatPrice from "@/utils/helpers/getFormatPrice";

export default {
  props: {
    empresaNom: {
      type: String,
      required: false
    },
    empresaId: {
      type: Number,
      required: true
    },
    actaId: {
      type: Number,
      required: true
    },
    isEditingActa: {
      type: Boolean,
      required: false
    }
  },
  components: {
    DetallePeriodosDDJJ,
    DeleteDialog,
    RecalcularInteresActaFiscalizacion,
    LogRecalculosInteresActasFiscalizacion
  },
  data() {
    return {
      searchIcon: enums.icons.SEARCH,
      itemsPerPageOptions: [10, 20, 30, 40, 50],
      itemPerPage: 10,
      loading: false,
      editIcon: enums.icons.EDIT,
      deleteIcon: enums.icons.DELETE,
      modalOpen: false,
      openModalDetail: false,
      periodos: [],
      periodoId: 0,
      searchTablaPeriodos: "",
      textDelete: null,
      titleDelete: "¿Desea eliminar el período?",
      totalSaldo: 0,
      totalInteres: 0,
      periodoToDelete: {},
      openDeleteDialog: false,
      editedIndex: -1,
      totalDeuda: 0,
      totalRecibido: 0,
      totalEsperado: 0,
      cantidadPeriodos: 0,
      headers: [
        {
          text: "Período",
          align: "start",
          value: "periodo",
          thClass: "bg-white text-dark"
        },
        { text: "Esperado", value: "esperado", align: "end" },
        { text: "Recibido", value: "recibido", align: "end" },
        { text: "Saldo", value: "saldo", align: "end" },
        { text: "Interés", value: "interes", align: "end" },
        { text: "Deuda", value: "deuda", align: "end" },
        { text: "Acciones", value: "actions", align: "end" }
      ],
      periodosItem: [],
      loadingExportExcel: false,
      recalcularInteresModal: false,
      historialRecalculosModal:false
    };
  },

  created() {
    this.$bus.$on("resetPeriodosDDJJ", data => {
      data == 0
        ? (this.openModalDetail = false)
        : (this.openModalDetail = true);
      var actaId = this.actaId;
      this.getPeriodos(actaId);
    });
    this.$bus.$on("openModalEditEmpresaActa", data => {
      this.modalOpen = data;
    });
    this.modalOpen = true;
    if (this.empresaId != -1) {
      this.getPeriodos(this.actaId);
    }
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      getPeriodosByActaId: "aportes/getPeriodosByActaId",
      getEmpresaById: "aportes/getEmpresaById",
      deleteActaById: "aportes/deleteActaById",
      getDetallePeriodo: "aportes/getDetallePeriodo",
      deletePeriodoOfActa: "aportes/deletePeriodoOfActa"
    }),
    closeModal() {
      this.modalOpen = false;
      this.$emit("close", false);
      this.$refs.form.reset();
    },
    async getPeriodos(actaId) {
      this.periodos = [];
      await this.getPeriodosByActaId({ actaId }).then(res => {
        this.periodos = res;
        this.cantidadPeriodos = this.periodos.length;
        this.calcularTotales();
        // if (this.periodos.length == 0) {
        //   var actaId = this.actaId;
        //   this.deleteActaById({ actaId }).then(res => {
        //     this.$bus.$emit("resetEmpresasSelected", true);
        //     if(this.isEditingActa==true){
        //       this.$router.push({ name:"GestiónProcesosFiscalización" ,params: {id:false}})
        //     }
        //   });
        // }
      });
    },
    addDecimals(x) {
      return getFormatPrice(x);
    },
    calcularTotales() {
      this.totalSaldo = 0;
      this.totalInteres = 0;
      this.totalEsperado = 0;
      this.totalRecibido = 0;
      this.totalDeuda = 0;
      var empresas = this.periodos;
      for (var i = 0; i < empresas.length; i++) {
        this.totalSaldo += empresas[i].saldo;
        this.totalInteres += empresas[i].interes;
        this.totalEsperado += empresas[i].esperado;
        this.totalRecibido += empresas[i].recibido;
        this.totalDeuda += empresas[i].deuda;
      }
    },
    exportExcel() {
      this.loadingExportExcel = true;
      let result = [];

      this.periodos?.forEach(async p => {
        let bodyParams = {
          actaId: this.actaId,
          periodo: p.periodo
        };
        const response = await this.getDetallePeriodo({ bodyParams });

        response.forEach(x =>
          result.push({
            ["Período"]: p.periodo,
            ["CUIL"]: x.cuil,
            ["Afiliado"]: x.benNom,
            ["Convenio"]: x.osNom,
            ["Origen cálculo esperado"]: x.origenCalculoEsperado,
            ["Esperado"]: x.esperado,
            ["Recibido"]: x.recibido,
            ["Saldo"]: x.saldo,
            ["Interés"]: x.interes,
            ["Deuda"]: x.deuda
          })
        );
      });
      // setTimeOut porque enviaba la hoja vacia por asincronismo
      setTimeout(() => {
        let formato = {
          filas: result,
          hoja: "Resultado"
        };
        helpersExcel.excelExport(
          formato,
          `Detalle períodos acta ${this.actaId}`
        );
      }, 1000);
      this.loadingExportExcel = false;
    },
    getDetalle(open, item) {
      this.periodoId = item.periodo;
      this.openModalDetail = open;
    },

    deletePeriodo(item) {
      this.textDelete =
        "Al eliminar el período, eliminará todas las declaraciones del mismo.";
      if (this.periodos.length == 1) {
        this.textDelete =
          "Al eliminar el periodo, eliminará el acta asociada. ";
      }

      this.periodoToDelete = item;
      this.openDeleteDialog = true;
    },
    async deletePeriodoConfirm() {
      var item = this.periodoToDelete;
      this.editedIndex = this.periodos.indexOf(item);
      this.periodos.splice(this.editedIndex, 1);
      this.quitarPeriodo(item);
      this.periodoToDelete = {};
      this.textDelete = null;
      this.openDeleteDialog = false;
    },

    async quitarPeriodo(item) {
      const bodyParams = {
        actaId: this.actaId,
        periodo: item.periodo
      };
      const res = await this.deletePeriodoOfActa({ bodyParams });

      if (res) {
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.calcularTotales();
        this.getPeriodos(this.actaId);
        this.openModalDetail = false;
      } else {
        this.setAlert({
          type: "error",
          message: "Ocurrió un problema al eliminar. Intente nuevamente"
        });
      }
    },
    toggleModalRecalculoIntereses() {
      this.recalcularInteresModal = !this.recalcularInteresModal;
    },
    toggleModalHistorialRecalculoIntereses() {
      this.historialRecalculosModal = !this.historialRecalculosModal;
    }
  }
};
</script>

<style lang="css" scoped>
.totales {
  font-weight: 500;
}
</style>
