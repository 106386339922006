var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',{staticClass:"py-0"},[_c('v-card-title',[_c('span',{staticClass:"py-0 primary--text"},[_vm._v("Historial de recálculos")])]),_c('v-data-table',{staticClass:"elevation-1 pt-0",attrs:{"headers":_vm.headers,"dense":"","search":_vm.searchTablaDetalle,"items":_vm.itemsRecalculo,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.toggleModalDetalleRecalculo(item.recalculoActaDetalles)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.seeIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Ver detalle del proceso")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.ultimoRegistro)?_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.openDeleteRecalculoInteres(item.actasFiscRecIntIds)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.anuladoIcon)+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Anular proceso")])])]}}],null,true)}),_c('v-card-actions',[_c('v-spacer',{staticClass:"pb-0"}),_c('v-btn',{staticClass:"pb-0 to-right",attrs:{"outlined":""},on:{"click":_vm.closeModal}},[_vm._v(" Cerrar ")])],1),(_vm.modalDetallesRecalculo)?_c('v-dialog',{attrs:{"max-width":"50%"},model:{value:(_vm.modalDetallesRecalculo),callback:function ($$v) {_vm.modalDetallesRecalculo=$$v},expression:"modalDetallesRecalculo"}},[_c('v-card',[_c('v-container',[_c('v-card-title',[_c('span',{staticClass:"py-0 primary--text"},[_vm._v("Detalle del recálculo")])]),_c('v-data-table',{staticClass:"elevation-1 pt-0",attrs:{"headers":_vm.headersDetalleRecalculo,"items":_vm.itemsDetalleRecalculo}}),_c('v-card-actions',[_c('v-spacer',{staticClass:"pb-0"}),_c('v-btn',{staticClass:"pb-0 to-right",attrs:{"outlined":""},on:{"click":function($event){return _vm.toggleModalDetalleRecalculo()}}},[_vm._v(" Cerrar ")])],1)],1)],1)],1):_vm._e(),_c('DeleteDialog',{attrs:{"textDelete":_vm.textDelete,"titleProp":"¿Anular?","openDelete":_vm.openDeleteDialog,"confirmButtonText":"Anular"},on:{"update:openDelete":function($event){_vm.openDeleteDialog=$event},"update:open-delete":function($event){_vm.openDeleteDialog=$event},"onDeleteItem":function($event){return _vm.deleteRecalculoConfirm()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }