<template>
  <v-card>
    <v-container class="pt-0">
      <v-card-title>
        <span class="pl-1 pt-0 primary--text">Recalcular interés</span>
      </v-card-title>
      <v-card-text class="py-0">
        <v-form
          v-model="isFormValid"
          ref="form-RecalculoInteres"
          form="form-RecalculoInteres"
          id="form-RecalculoInteres"
          @submit.prevent="saveRecalculoInteres()"
        >
          <v-row>
            <!-- Fecha recalculo interes -->

            <v-col cols="12" class="py-0">
              <v-menu
                v-model="menuFecha"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="py-0"
                    v-model="fechaSelected"
                    label="Fecha de recálculo"
                    :append-icon="calendarIcon"
                    v-bind="attrs"
                    autocomplete="not"
                    clearable
                    hint="Formato DD/MM/AAAA"
                    @blur="fecha = parseDateToIso(fechaSelected)"
                    :rules="rules.required.concat(rules.validDate)"
                    outlined
                    dense
                    v-mask="'##/##/####'"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fecha"
                  no-title
                  @change="fechaSelected = formatDate(fecha)"
                  @input="menuFecha = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="py-0">
        <v-spacer class="py-0"></v-spacer>
        <v-btn outlined class="py-0" @click="closeModal"> Cancelar </v-btn>
        <v-btn
          class="py-0"
          type="submit"
          :disabled="!isFormValid || loadingSaveBtn"
          :loading="loadingSaveBtn"
          form="form-RecalculoInteres"
          color="primary"
        >
          Procesar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";

export default {
  name: "RecalcularInteresActaFiscalizacion",
  directives: { mask },
  props: {
    actasFiscalizacionId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      menuFecha: false,
      fechaSelected: null,
      isFormValid: false,
      fecha: null,
      calendarIcon: enums.icons.CALENDAR,
      rules: rules,
      loadingSaveBtn: false
    };
  },
  methods: {
    ...mapActions({
      recalcularInteresActaFiscalizacion:
        "aportes/recalcularInteresActaFiscalizacion",
      setAlert: "user/setAlert"

    }),
    async saveRecalculoInteres() {
      this.loadingSaveBtn = true;
      const data = {
        ActasFiscalizacionId: this.actasFiscalizacionId,
        FechaRecalculo: this.fecha
      };
      try {
        const res = await this.recalcularInteresActaFiscalizacion(data);
        if (res.status === 200) {
          this.setAlert({ type: "success", message: "Recalculado con éxito." });
          this.$emit("updatePeriodosDDJJ");
          this.closeModal();
        }
      } catch (error) {
        this.loadingSaveBtn = false;
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    closeModal() {
      this.$emit("toggleModalRecalculoIntereses");
    }
  }
};
</script>

<style></style>
