<template>
  <div>
    <v-toolbar-title class="mt-4">Otros conceptos</v-toolbar-title>
    <br />
    <v-data-table
      :headers="headers"
      :header-props="{ sortIcon: null }"
      class="elevation-1"
      :items="otrosConceptos"
      item-key="ActasFiscalizacionConceptoId"
      :search="searchTablaConceptos"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': itemsPerPageOptions
      }"
      :items-per-page="itemPerPage"
    >
      <template v-slot:[`item.esperado`]="{ item }">
        <span>{{ addDecimals(item.esperado) }}</span>
      </template>
      <template v-slot:[`item.recibido`]="{ item }">
        <span>{{ addDecimals(item.recibido) }}</span>
      </template>
      <template v-slot:[`item.saldo`]="{ item }">
        <span>{{ addDecimals(item.saldo) }}</span>
      </template>
      <template v-slot:[`item.interes`]="{ item }">
        <span>{{ addDecimals(item.interes) }}</span>
      </template>
      <template v-slot:[`item.deuda`]="{ item }">
        <span>{{ addDecimals(item.deuda) }}</span>
      </template>
      <template v-slot:[`item.importeInteres`]="{ item }">
        <span>{{ addDecimals(item.importeInteres) }}</span>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <v-col cols="10">
              <v-text-field
                v-model="searchTablaConceptos"
                :append-icon="searchIcon"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="2" md="2" align="end">
              <v-btn color="primary" @click="nuevoConcepto()" class="to-right">
                Nuevo
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" small v-on="on" @click="nuevoConcepto(item)">
              {{ editIcon }}
            </v-icon>
          </template>
          <span>Editar concepto</span>
        </v-tooltip>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              @click="deleteConcepto(item)"
            >
              {{ deleteIcon }}
            </v-icon>
          </template>
          <span>Quitar concepto</span>
        </v-tooltip>
      </template>
      <template v-slot:[`body.append`]="{}">
        <tr class="totales text-center">
          <td></td>
          <td class="text-start"><strong>Totales por conceptos</strong></td>
          <td></td>
          <td class="text-right">
            <strong>{{ addDecimals(totalImporte) }}</strong>
          </td>
          <td class="text-right">
            <strong>{{ addDecimals(totalInteres) }}</strong>
          </td>
          <td class="text-end">
            <strong> {{ addDecimals(otrosConceptosImporte) }}</strong>
          </td>
          <td></td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog
      v-if="openNewConcepto"
      v-model="openNewConcepto"
      max-width="45%"
      @keydown.esc="closeModalNewConcepto"
      persistent
    >
      <NuevoConcepto
        :otroConcepto="otroConcepto"
        :empresaSelected="empresaSelected"
        :actaId="actaId"
        :newActaFiscalizacionId="newActaFiscalizacionId"
        :actasFiscalizacionId="actasFiscalizacionId"
        @closeModalNewConcepto="closeModalNewConcepto"
        @conceptosOtros="conceptosOtros"
      >
      </NuevoConcepto>
    </v-dialog>
    <DeleteDialog
      titleProp="¿Desea eliminar el concepto?"
      :maxWidth="'30%'"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </div>
</template>
<script>
import enums from "@/utils/enums/index.js";
import { mapActions, mapGetters } from "vuex";
import DetallePeriodosDDJJ from "@/components/modules/cuotas/aportes/DetallePeriodosDDJJ.vue";
import DeleteDialog from "@/components/shared/DeleteDialog";
import getFormatPrice from "@/utils/helpers/getFormatPrice";
import NuevoConcepto from "@/components/modules/cuotas/aportes/NuevoConcepto";

export default {
  props: {
    actasFiscalizacionId: {
      type: Number
    },
    empresaId: {
      type: Number
    },
    newActaFiscalizacionId: {
      type: Number
    },
    actaId: {
      type: Number
    },
    isEditingActa: {
      type: Boolean,
      required: false
    },
    empresaSelected: {
      type: Object
    }
  },
  components: {
    DetallePeriodosDDJJ,
    DeleteDialog,
    NuevoConcepto
  },
  data() {
    return {
      searchIcon: enums.icons.SEARCH,
      itemsPerPageOptions: [10, 20, 30, 40, 50],
      itemPerPage: 10,
      loading: false,
      showDeleteModal: false,
      deleteIcon: enums.icons.DELETE,
      editIcon: enums.icons.EDIT,
      modalOpen: false,
      openModalDetail: false,
      otrosConceptos: [],
      searchTablaConceptos: "",
      textDelete: null,
      totalSaldo: 0,
      totalInteres: 0,
      conceptToDelete: {},
      openDeleteDialog: false,
      editedIndex: -1,
      totalDeuda: 0,
      totalRecibido: 0,
      totalEsperado: 0,
      otrosConceptosImporte: 0,
      cantidadPeriodos: 0,
      totalImporte: 0,
      totalInteres: 0,
      totalImporteInteres: 0,
      openNewConcepto: false,
      otroConcepto: null,
      headers: [
        {
          text: "Concepto",
          align: "start",
          value: "concepto"
        },
        { text: "Período", value: "periodo", align: "end" },
        { text: "Cantidad", value: "cantidad", align: "end" },
        { text: "Importe", value: "importeString", align: "end" },
        { text: "Interés", value: "interesString", align: "end" },
        { text: "Total", value: "importeInteres", align: "end" },
        { text: "Observaciones", value: "observaciones", align: "left" },
        { text: "Acciones", value: "actions", align: "end" }
      ]
    };
  },
  created() {
    this.conceptosOtros();
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      deleteConceptoActasFiscalizacion:
        "aportes/deleteConceptoActasFiscalizacion",
      getConceptosManualesByActaId: "aportes/getConceptosManualesByActaId"
    }),
    closeModal() {
      this.modalOpen = false;
      this.$emit("close", false);
      this.$refs.form.reset();
    },
    nuevoConcepto(item) {
      this.openNewConcepto = true;
      this.otroConcepto = item;
    },
    closeModalNewConcepto() {
      this.openNewConcepto = false;
    },
    addDecimals(x) {
      return getFormatPrice(x);
    },
    async conceptosOtros() {
      if (this.actasFiscalizacionId != null) {
        const data = await this.getConceptosManualesByActaId(
          this.actasFiscalizacionId
        );
        this.otrosConceptos = data.conceptosManuales.map(concepto => ({
          ...concepto,
          importeInteres: concepto.importe + concepto.interes
        }));
        this.otrosConceptosImporte = data.sumaImportes;

        // Calcular totales
        this.totalImporte = this.otrosConceptos.reduce(
          (total, concepto) => total + concepto.importe,
          0
        );
        this.totalInteres = this.otrosConceptos.reduce(
          (total, concepto) => total + concepto.interes,
          0
        );
        this.totalImporteInteres = this.otrosConceptos.reduce(
          (total, concepto) => total + concepto.importeInteres,
          0
        );
      }
    },
    addDecimals(x) {
      return getFormatPrice(x);
    },
    calcularTotales() {
      this.totalSaldo = 0;
      this.totalInteres = 0;
      this.totalEsperado = 0;
      this.totalRecibido = 0;
      this.totalDeuda = 0;
      var empresas = this.conceptos;
      for (var i = 0; i < empresas.length; i++) {
        this.totalSaldo += empresas[i].saldo;
        this.totalInteres += empresas[i].interes;
        this.totalEsperado += empresas[i].esperado;
        this.totalRecibido += empresas[i].recibido;
        this.totalDeuda += empresas[i].deuda;
      }
    },
    deleteConcepto(id) {
      this.showDeleteModal = true;
      this.idToDelete = id.actasFiscalizacionConceptoId;
    },
    async confirmDelete() {
      const response = await this.deleteConceptoActasFiscalizacion(
        this.idToDelete
      );
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.conceptosOtros();
      }
    }
  }
};
</script>

<style lang="css" scoped>
.totales {
  font-weight: 400;
  font-size: 14px;
}
</style>
