<template>
  <v-card>
    <v-container class="py-0">
      <v-card-title>
        <span class="py-0 primary--text">Historial de recálculos</span>
      </v-card-title>
      <v-data-table
        :headers="headers"
        class="elevation-1 pt-0"
        dense
        :search="searchTablaDetalle"
        :items="itemsRecalculo"
        :loading="loading"
      >
        <!-- <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="searchTablaDetalle"
                  :append-icon="searchIcon"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-toolbar>
        </template> -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="toggleModalDetalleRecalculo(item.recalculoActaDetalles)"
              >
                {{ seeIcon }}
              </v-icon>
            </template>
            <span>Ver detalle del proceso</span>
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="openDeleteRecalculoInteres(item.actasFiscRecIntIds)"
                v-if="item.ultimoRegistro"
              >
                {{ anuladoIcon }}
              </v-icon>
            </template>
            <span>Anular proceso</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer class="pb-0"></v-spacer>
        <v-btn outlined class="pb-0 to-right" @click="closeModal">
          Cerrar
        </v-btn>
      </v-card-actions>
      <!-- Modal detalle de recalculo -->
      <v-dialog
        v-if="modalDetallesRecalculo"
        v-model="modalDetallesRecalculo"
        max-width="50%"
      >
        <v-card>
          <v-container>
            <v-card-title>
              <span class="py-0 primary--text">Detalle del recálculo</span>
            </v-card-title>
            <v-data-table
              :headers="headersDetalleRecalculo"
              class="elevation-1 pt-0"
              :items="itemsDetalleRecalculo"
            >
            </v-data-table>
            <v-card-actions>
              <v-spacer class="pb-0"></v-spacer>
              <v-btn
                outlined
                class="pb-0 to-right"
                @click="toggleModalDetalleRecalculo()"
              >
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>
      <DeleteDialog
        :textDelete="textDelete"
        titleProp="¿Anular?"
        :openDelete.sync="openDeleteDialog"
        confirmButtonText="Anular"
        @onDeleteItem="deleteRecalculoConfirm()"
      />
    </v-container>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import DeleteDialog from "@/components/shared/DeleteDialog";

export default {
  name: "LogRecalculosInteresActasFiscalizacion",
  props: {
    actasFiscalizacionId: {
      type: Number,
      default: null
    }
  },
  components: {
    DeleteDialog
  },

  data() {
    return {
      loading: false,
      searchIcon: enums.icons.SEARCH,
      editIcon: enums.icons.EDIT,
      anuladoIcon: enums.icons.CIRCLE_NONE,
      seeIcon: enums.icons.SEE,
      modalDetallesRecalculo: false,
      itemsDetalleRecalculo: [],
      headersDetalleRecalculo: [
        {
          text: "Afiliado",
          value: "datosAfiliado.nombreAfiliado",
          align: "start",
          sortable: false
        },
        {
          text: "CUIL",
          value: "datosAfiliado.cuil",
          align: "start",
          sortable: false
        },
        {
          text: "Período",
          align: "start",
          value: "datosAfiliado.periodo",
          sortable: false
        },
        {
          text: "Interés anterior",
          align: "end",
          value: "interesAnterior",
          sortable: false
        },
        {
          text: "Nuevo interés",
          align: "end",
          value: "nuevoInteres",
          sortable: false
        }
      ],
      deleteIcon: enums.icons.DELETE,
      textDelete: "",
      searchTablaDetalle: "",
      openDeleteDialog: false,
      headers: [
        {
          text: "Fecha alta",
          value: "fecAlta",
          align: "start",
          sortable: false
        },
        { text: "Usuario", value: "usuAlta", align: "start", sortable: false },
        {
          text: "Fecha de recálculo",
          align: "end",
          value: "fechaRecalculo",
          sortable: false
        },
        {
          text: "Interés anterior",
          align: "end",
          value: "interesAnterior",
          sortable: false
        },
        {
          text: "Nuevo interés",
          align: "end",
          value: "nuevoInteres",
          sortable: false
        },
        { text: "Acciones", value: "actions", align: "end" }
      ],
      itemsRecalculo: []
    };
  },
  created() {
    this.getDetalle();
  },

  methods: {
    ...mapActions({
      getRecalculosInteresActaFiscalizacion:
        "aportes/getRecalculosInteresActaFiscalizacion",
      deleteRecalculoInteres: "aportes/deleteRecalculoInteres",
      setAlert: "user/setAlert"
    }),
    closeModal() {
      this.$emit("toggleModalHistorialRecalculoIntereses");
    },

    async getDetalle() {
      this.loading = true;
      const response = await this.getRecalculosInteresActaFiscalizacion(
        this.actasFiscalizacionId
      );
      this.itemsRecalculo = response;
      this.loading = false;
    },
    toggleModalDetalleRecalculo(itemsDetalle) {
      this.itemsDetalleRecalculo = itemsDetalle ?? [];
      this.modalDetallesRecalculo = !this.modalDetallesRecalculo;
    },
    openDeleteRecalculoInteres(items) {
      this.textDelete = "Los intereses tomarán su valor anterior.";
      this.itemsRecalculoInteresToDelete = items;
      this.openDeleteDialog = true;
    },

    async deleteRecalculoConfirm() {
      try {
        const response = await this.deleteRecalculoInteres(
          this.itemsRecalculoInteresToDelete
        );
        if (response.status == 200) {
          this.setAlert({ type: "success", message: "Eliminado con éxito." });
          this.getDetalle();

          this.$emit("updatePeriodosDDJJ");
        }
      } catch (error) {
        this.setAlert({ type: "warning", message: "Ocurrió un error" });
      } finally {
        this.openDeleteDialog = false;
      }
      this.itemsRecalculoInteresToDelete = [];
      this.textDelete = null;
    }
  }
};
</script>

<style lang="css" scoped></style>
