<template>
  <v-container>
    <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
    <PageHeader :title="title" />
    <v-card>
      <v-container>
        <v-form v-model="isFormValid">
          <v-row>
            <v-col cols="4" sm="6" md="4" class="pb-0 pt-0">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaSelected"
                    label="Fecha"
                    :append-icon="calendarIcon"
                    v-bind="attrs"
                    hint="Formato DD/MM/AAAA"
                    @blur="fechaDatePicker = parseDateToIso(fechaSelected)"
                    outlined
                    dense
                    clearable
                    v-mask="'##/##/####'"
                    v-on="on"
                    :rules="
                      rules.required.concat([
                        !(fechaSelected && vencimientoSelected) ||
                          new Date(parseDateToIso(fechaSelected)) <=
                            new Date(parseDateToIso(vencimientoSelected)) ||
                          'Formato incorrecto'
                      ])
                    "
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaDatePicker"
                  no-title
                  @change="fechaSelected = formatDate(fechaDatePicker)"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="4" sm="6" md="4" class="pb-0 pt-0">
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="vencimientoSelected"
                    label="Vencimiento"
                    :append-icon="calendarIcon"
                    v-bind="attrs"
                    hint="Formato DD/MM/AAAA"
                    v-mask="'##/##/####'"
                    clearable
                    @blur="
                      fechaVencDatePicker = parseDateToIso(vencimientoSelected)
                    "
                    outlined
                    dense
                    v-on="on"
                    :rules="
                      rules.required.concat([
                        !(fechaSelected && vencimientoSelected) ||
                          new Date(parseDateToIso(fechaSelected)) <=
                            new Date(parseDateToIso(vencimientoSelected)) ||
                          'Formato incorrecto'
                      ])
                    "
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaVencDatePicker"
                  no-title
                  @change="
                    vencimientoSelected = formatDate(fechaVencDatePicker)
                  "
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="4" sm="6" md="4" class="pb-0 pt-0">
              <div v-if="!actaIdToEdit">
                <v-autocomplete
                  ref="empresa-autocomplete"
                  cache-items
                  v-model="empresaSelected"
                  :items="empresas"
                  :search-input.sync="empresaAutocompleteInput"
                  item-text="value"
                  return-object
                  outlined
                  dense
                  :rules="rules.required"
                  :readonly="newActaFiscalizacionId != null"
                  :filled="newActaFiscalizacionId != null"
                  :loading="empresasLoading"
                  autocomplete="off"
                  label="Empresa"
                  hint="Nombre o CUIT"
                  persistent-hint
                ></v-autocomplete>
              </div>
              <div v-else>
                <v-text-field
                  v-model="empNombreCuit"
                  label="Empresa"
                  dense
                  outlined
                  filled
                  readonly
                ></v-text-field>
              </div>
            </v-col>
            <v-col cols="4" sm="6" md="4" class="pb-0 pt-0">
              <v-text-field
                v-model="telefono"
                label="Teléfono"
                item-text="telefono"
                item-value="id"
                dense
                outlined
                :rules="rules.telephone.concat(rules.maxLength(telefono, 50))"
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="6" md="4" class="pb-0 pt-0">
              <v-text-field
                v-model="email"
                label="Email"
                item-text="email"
                item-value="id"
                dense
                outlined
                :rules="rules.email.concat(rules.maxLength(email, 100))"
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="6" md="4" class="pb-0 pt-0">
              <v-autocomplete
                cache-items
                v-model="inspectorSelected"
                :items="inspectores"
                item-text="provNom"
                item-value="provId"
                outlined
                dense
                clearable
                autocomplete="not"
                label="Inspector"
                persistent-hint
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" sm="6" md="4" class="pb-0 pt-0">
              <v-select
                v-model="entidadSelected"
                :items="entidades"
                item-text="value"
                item-value="id"
                label="Entidad fiscalizadora"
                outlined
                dense
                :rules="rules.required"
              >
              </v-select>
            </v-col>
            <v-col cols="6" sm="6" md="4" class="pb-0 pt-0">
              <div v-if="!actaIdToEdit">
                <v-select
                  v-model="estadoSelected"
                  :items="estado"
                  item-text="value"
                  item-value="id"
                  label="Estado inicial"
                  outlined
                  :readonly="newActaFiscalizacionId != null"
                  :filled="newActaFiscalizacionId != null"
                  dense
                  cache-items
                  :rules="rules.required"
                  persistent-hint
                >
                </v-select>
              </div>
            </v-col>
            <v-col cols="12" class="pb-0 pt-0">
              <v-textarea
                v-model="observaciones"
                label="Observaciones"
                item-text="observaciones"
                item-value="id"
                dense
                :rules="[rules.maxLength(observaciones, 500)]"
                outlined
                rows="2"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-row class="d-flex flex-row-reverse">
              <v-btn
                :disabled="!isFormValid || banderaGuardar"
                color="primary"
                :loading="banderaGuardar"
                @click="saveEdit()"
              >
                Guardar
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-form>
      </v-container>
    </v-card>
    <div v-if="showPeriodoDDJJ">
      <PeriodosDDJJ
        :actaId="actaIdToEdit"
        :empresaNom="empNombre"
        :empresaId="empId"
        :isEditingActa="isEditingActa"
      ></PeriodosDDJJ>
    </div>
    <div v-if="showPeriodoDDJJ || this.newActaFiscalizacionId != null">
      <OtrosConceptos
        :newActaFiscalizacionId="newActaFiscalizacionId"
        :actaId="actaIdToEdit"
        :empresaSelected="empresaSelected"
        :empresaId="empId"
        :actasFiscalizacionId="actasFiscalizacionId"
        :isEditingActa="isEditingActa"
      ></OtrosConceptos>
    </div>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions, mapGetters } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import PeriodosDDJJ from "@/components/modules/cuotas/aportes/PeriodosDDJJ";
import OtrosConceptos from "@/components/modules/cuotas/aportes/OtrosConceptos";
import { mask } from "vue-the-mask";
import GoBackBtn from "@/components/shared/GoBackBtn";

export default {
  name: "EditActa",
  directives: { mask },
  components: { PageHeader, PeriodosDDJJ, GoBackBtn, OtrosConceptos },

  data: vm => ({
    routeToGo: "",
    actaIdToEdit: null,
    isEditingActa: true,
    title: enums.titles.EDITAR_ACTA,
    calendarIcon: enums.icons.CALENDAR,
    acta: {},
    empresaSelected: null,
    empresas: [],
    estadoSelected: null,
    estado: [],
    empresaAutocompleteInput: null,
    menu1: false,
    entidades: [],
    entidadSelected: null,
    showPeriodoDDJJ: false,
    menu2: false,
    empId: null,
    empNombre: null,
    fechaDatePicker: null,
    vencimientoSelected: null,
    fechaSelected: null,
    fechaVencDatePicker: null,
    empNombreCuit: null,
    telefono: null,
    email: null,
    banderaGuardar: false,
    inspectorSelected: null,
    inspectorId: null,
    entidadFiscalizadoraId: null,
    inspectores: [],
    isFormValid: false,
    observaciones: null,
    actasFiscalizacionId: null,
    empresasLoading: false,
    rules: rules,
    newActaFiscalizacionId: null
  }),
  watch: {
    empresaAutocompleteInput(val) {
      if (val && val.length >= 3) {
        this.getEmpresasFilter();
      }
    }
  },
  created() {
    this.actaIdToEdit = this.$route.params.id;
    this.setSelects();
    if (this.actaIdToEdit != null) {
      this.loadActa();
      this.title = `${enums.titles.EDITAR_ACTA} n° ${this.actaIdToEdit}`;
    } else {
      this.title = enums.titles.NUEVA_ACTA;
    }
    this.routeToGo = this.$route.params.parent;
  },
  methods: {
    ...mapActions({
      getEmpresaByActaId: "aportes/getEmpresaByActaId",
      updateActaFiscalizacion: "aportes/updateActaFiscalizacion",
      fetchEmpresasByQuery: "aportes/fetchEmpresasByQuery",
      fetchInspectores: "aportes/fetchInspectores",
      fetchEntidades: "aportes/fetchEntidadesFiscalizadoras",
      getEmpresaById: "aportes/getEmpresaById",
      getEstadosActasIniciales: "aportes/getEstadosActasIniciales",
      setAlert: "user/setAlert"
    }),
    async getEmpresasFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        this.empresasLoading = true;
        const res = await this.fetchEmpresasByQuery({
          input: this.empresaAutocompleteInput
        });
        this.empresas = res;
        this.empresasLoading = false;
      }, 1000);
    },
    async setSelects() {
      const res = await this.fetchInspectores();
      this.inspectores = res;
      const entidades = await this.fetchEntidades();
      this.entidades = entidades;
      const estInicial = await this.getEstadosActasIniciales();
      this.estado = estInicial;
    },
    async loadActa() {
      const response = await this.getEmpresaByActaId(this.actaIdToEdit);
      this.acta = response;
      this.showPeriodoDDJJ = true;
      this.empNombre = this.acta.empNombre;
      this.actasFiscalizacionId = this.acta.actasFiscalizacionId;
      this.empId = this.acta.empId;
      this.email = this.acta.email || null;
      this.setActa();
    },
    setActa() {
      if (this.acta) {
        this.fechaDatePicker = this.parseDateToIso(this.acta.fecha);
        this.fechaSelected = this.formatDate(this.fechaDatePicker);
        this.fechaVencDatePicker = this.parseDateToIso(this.acta.fechaVenc);
        this.vencimientoSelected = this.formatDate(this.fechaVencDatePicker);
        this.empNombreCuit = this.acta.empNombre + " - " + this.acta.cuit;
        this.inspectorSelected = this.acta.inspectorId;
        this.entidadSelected = this.acta.entidadFiscalizadoraId;
        this.telefono = this.acta.telefono;
        this.email = this.acta.email;
        this.observaciones = this.acta.observaciones;
      }
    },
    async saveEdit() {
      this.banderaGuardar = true;

      const inspector = this.inspectores.find(
        x => x.provId === this.inspectorSelected
      );
      const entidad = this.entidades.find(x => x.id === this.entidadSelected);
      const data = {
        actasFiscalizacionId: this.actasFiscalizacionId,
        fecha: this.parseDateToIso(this.fechaSelected),
        fechaVenc: this.parseDateToIso(this.vencimientoSelected),
        telefono: this.telefono,
        email: this.email,
        empId: this.actaIdToEdit != null ? this.empId : this.empresaSelected.id,
        estadoInicialId: this.actaIdToEdit != null ? null : this.estadoSelected,
        inspectorId: typeof inspector !== "undefined" ? inspector.provId : null,
        entidadFiscalizadoraId:
          typeof entidad !== "undefined" ? entidad.id : null,
        observaciones: this.observaciones
      };
      const res = await this.updateActaFiscalizacion(data);
      if (res.status === 200) {
        this.newActaFiscalizacionId = res.data.data;
        this.actasFiscalizacionId = this.newActaFiscalizacionId;
        this.setAlert({ type: "success", message: "Guardado con éxito" });
      }
      this.banderaGuardar = false;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return;
      const [day, month, year] = date.split("/");
      return `${month}/${day}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }
  }
};
</script>
<style lang="scss" scoped></style>
