var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar-title',[_vm._v("Detalle del período "),_c('strong',[_vm._v(_vm._s(_vm.periodoProp))])]),_c('br'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"id":"tablaDetalle","headers":_vm.headers,"header-props":{ sortIcon: null },"search":_vm.searchTablaDetalle,"items":_vm.empleadosItems,"loading":_vm.loading,"footer-props":{
      'items-per-page-options': _vm.itemsPerPageOptions
    },"items-per-page":_vm.itemPerPage},scopedSlots:_vm._u([{key:"item.esperado",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.addDecimals(item.esperado)))])]}},{key:"item.recibido",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.addDecimals(item.recibido)))])]}},{key:"item.saldo",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.addDecimals(item.saldo)))])]}},{key:"item.interes",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.addDecimals(item.interes)))])]}},{key:"item.deuda",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.addDecimals(item.deuda)))])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.searchTablaDetalle),callback:function ($$v) {_vm.searchTablaDetalle=$$v},expression:"searchTablaDetalle"}})],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""}},[[_c('v-text-field',{attrs:{"type":"link"},on:{"click":function($event){return _vm.quitarEmpleado(true, item)}}},[_vm._v(" Quitar ")])],_c('span',[_vm._v("Quitar")])],2),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.deleteIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Quitar")])])]}},{key:"body.append",fn:function(ref){
    var headers = ref.headers;
return [_c('tr',{staticClass:"totales text-end"},_vm._l((headers),function(header,i){return _c('td',{key:i},[(header.value == 'benNom')?_c('div',[_c('strong',[_vm._v(" Totales")])]):_vm._e(),(header.value == 'esperado')?_c('div',[_c('strong',[_vm._v(" "+_vm._s(_vm.addDecimals(_vm.totalEsperado)))])]):_vm._e(),(header.value == 'recibido')?_c('div',[_c('strong',[_vm._v(" "+_vm._s(_vm.addDecimals(_vm.totalRecibido)))])]):_vm._e(),(header.value == 'saldo')?_c('div',[_c('strong',[_vm._v(_vm._s(_vm.addDecimals(_vm.totalSaldo)))])]):_vm._e(),(header.value == 'interes')?_c('div',[_c('strong',[_vm._v(_vm._s(_vm.addDecimals(_vm.totalInteres)))])]):_vm._e(),(header.value == 'deuda')?_c('div',[_c('strong',[_vm._v(_vm._s(_vm.addDecimals(_vm.totalDeuda)))])]):_c('div')])}),0)]}}],null,true)}),_c('DeleteDialog',{attrs:{"textDelete":_vm.textDelete,"openDelete":_vm.openDeleteDialog},on:{"update:openDelete":function($event){_vm.openDeleteDialog=$event},"update:open-delete":function($event){_vm.openDeleteDialog=$event},"onDeleteItem":function($event){return _vm.deleteEmpleadoConfirm()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }